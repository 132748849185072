import _ from 'lodash-es'
import {isLockdownEnv, cleanObject, featureOn} from '../lib/utils.js'
import I18n from '../i18n.js'
import lkt from '../lkt.js'
import {getEnt, getAdmin, cachedTenancy, getEntGuid} from './tenancy-helper.js'

/* istanbul ignore next */
const isDeveloperTest = () => {
  // For visual testing of Wootric Modal UI uncomment these lines
  // globalThis.wootric_survey_immediately = true
  // globalThis.wootric_no_surveyed_cookie = true
  // return true
}

const wootricEnabled = () =>
  isDeveloperTest() ||
  (!!lkt.config.wootric_token &&
    getEnt() &&
    featureOn('l4e_rollout_wootric_survey') &&
    !isLockdownEnv())

const wootricInitialized = () => !!globalThis.window.WootricSurvey

// const firstTenancyDate = () =>
//   _.head(_.sortBy(_.map(tenancies(), t => moment(t.created_at).unix())))

const userId = ({admin, ent}) =>
  `${admin.public_id_sha}@${_.kebabCase(ent.name)}`

const runSurvey = () => {
  try {
    const ent = getEnt()
    const admin = getAdmin()
    // due to bugs in Wootric SDK we must set settings to the window, despite of
    // the advertised ability of passing it to `WootricSurvey.run()` function
    globalThis.window.wootricSettings = {
      email: userId({admin, ent}),
      // Per agreement with PM we won't pass the date, assuming the first open of MES Console
      // will start the Wootric Survey timer (30 days)
      // Having used tenancy creation date was a problem, as that date may be too far away back (license sold)
      // from the moment users enter MES Console for a first time, hence the Survey would be shown immediately
      // and before users had a chance to use the system to build a feedback
      account_token: lkt.config.wootric_token,
      language: I18n.locale,
      modal_footprint: 'compact',
      aria: true,
      scale_color: 'gradient',
      properties: {
        ..._.mapKeys(
          cleanObject(
            _.pick(ent, ['name', 'type', 'seats', 'country_code', 'language'])
          ),
          (value, key) => `ent_${key}${_.isNumber(value) ? '_amount' : ''}`
        ),
        ent_activation_amount:
          _.isNumber(ent.seats) && ent.seats > 0
            ? ent.used_seats <= ent.seats
              ? Math.ceil((ent.used_seats / ent.seats) * 100)
              : 100
            : 0,
        admin_role: admin.role,
        admin_language: I18n.locale,
      },
    }
    globalThis.window.WootricSurvey.run(globalThis.window.wootricSettings)
  } catch (e) {
    /* istanbul ignore if */
    if (e) {
      // eslint-disable-next-line no-console
      console?.error('Failed to run WootricSurvey:', e)
    }
  }
}

const initWootric = () => {
  if (wootricEnabled()) {
    // uncomment to force showing survey for dev purposes
    // and do not forget to comment this back before finishing the commit
    // globalThis.wootric_survey_immediately = true
    // globalThis.wootric_no_surveyed_cookie = true

    if (wootricInitialized()) {
      runSurvey()
    } else {
      try {
        const beacon = globalThis.document.createElement('script')
        beacon.type = 'text/javascript'
        beacon.id = 'wootric-beacon'
        beacon.async = true
        beacon.src = 'https://cdn.wootric.com/wootric-sdk.js'
        const currentTenancy = cachedTenancy()
        beacon.onload = () => {
          // assuming that async loading of SDK may take significant amount of time
          // verify that current tenancy (that could have changed already for multi
          // tenancy users) allows Survey
          if (getEntGuid(currentTenancy) === getEntGuid()) runSurvey()
        }
        globalThis.document.body.appendChild(beacon)
      } catch (e) {
        /* istanbul ignore if */
        if (e) {
          // eslint-disable-next-line no-console
          console?.error('Failed to run load WootricSurvey SDK:', e)
        }
      }
    }
  } else if (wootricInitialized()) {
    try {
      globalThis.window.WootricSurvey.stop()
    } catch (e) {
      /* istanbul ignore if */
      if (e) {
        // eslint-disable-next-line no-console
        console?.error('Failed to stop WootricSurvey:', e)
      }
    }
  }
}

export default initWootric
