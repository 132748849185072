import _ from 'lodash-es'
import {assignLocation} from './location-proxy.js'
import {toAbsoluteUrl, serverUrlFor, destinationParams} from './utils.js'
import {resetChanges} from './change-tracker.js'

const loggedEnts = () =>
  JSON.parse(globalThis.sessionStorage.getItem('X-LOOKOUT-ENTS') || '[]')

export const isLoggedEnt = entGuid => _.includes(loggedEnts(), entGuid)

export const saveLoggedEnt = entGuid =>
  globalThis.sessionStorage.setItem(
    'X-LOOKOUT-ENTS',
    JSON.stringify([...loggedEnts(), entGuid])
  )

/*
 * Clears session storage and redirects to logout page.
 */
export const logout = ({url = '/logout', withPath} = {}) => {
  globalThis.sessionStorage.removeItem('X-LOOKOUT-ENTS')
  resetChanges() // to avoid browser navigate confirmation dialog
  assignLocation(
    `${toAbsoluteUrl(serverUrlFor(url))}?${destinationParams({withPath})}`
  )
}
