import _ from 'lodash-es'
import * as lookoutRequest from '@lookout/request'
import {handleError, handle401} from '../lib/error-helper.js'
import lkt from '../lkt.js'
import {getEntGuid} from './tenancy-helper.js'
import {apiUrlFor} from './api.js'

// The use of _.get(lookoutRequest, 'request') is to cheat on Webpack module
// resolution optimizer. Since we stub ES module object, we want to make sure
// we access module exports of that object and not from the standalone module file
const {parseResponse} = lookoutRequest

// minutes to milliseconds
const borderPatrolTimeout = () => 1000 * 60 * lkt.config.auth_session_timeout

// the session bouncer cares only for requests that are covered with BorderPatrol
const isOurApi = url =>
  !!_.find(_.values(lkt.config.api), api => _.startsWith(url, api))

let bouncerTimer

/*
 * Tip the bouncer to let us stay inside the club (app) until the time is up.
 * If no tip (api call) happens during the wait time the bouncer will get angry
 * and throw us behind the club doors (logout).
 */
export const tipSessionBouncer = requestUrl => {
  const patienceTime = borderPatrolTimeout()
  if (!(patienceTime > 0)) return
  if (!isOurApi(requestUrl)) return
  if (bouncerTimer) globalThis.clearTimeout(bouncerTimer)
  bouncerTimer = globalThis.setTimeout(async () => {
    // send a dummy request to see if BorderPatrol session is still alive
    const response = await _.get(
      lookoutRequest,
      'fetchJSON'
    )(apiUrlFor('/console/config'))
    // if session is over the bouncer forces everybody leave the club
    if (response.status === 401) handle401(await parseResponse(response))
  }, patienceTime + 1000 * 60 * 1) // 1 extra minute 🤞, to check after BP session is over
}

// X-LOOKOUT-ENT-GUID is set for services/borderpatrol for auth purposes
export const borderPatrolHeaders = ({entGuid = getEntGuid()} = {}) =>
  entGuid && {'X-LOOKOUT-ENT-GUID': entGuid}

const request = async (
  url,
  {skipErrorHandling, skipBorderPatrolHeaders, headers, ...opts} = {}
) =>
  _.get(lookoutRequest, 'request')(url, {
    ...opts,
    headers: {
      ...(!skipBorderPatrolHeaders && borderPatrolHeaders()),
      ...headers,
    },
    onAfterFetch: () => tipSessionBouncer(url),
    onError: ({response, body}) =>
      !skipErrorHandling && handleError(response.status, body), // handle supported error statuses (401)
  })

export default request
