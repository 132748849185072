import _ from 'lodash-es'
import {handleActions} from 'redux-actions'
import I18n from '../../i18n.js'
import createSelector from '../../lib/create-selector.js'
import {FETCH_RISK_EXPOSURE} from '../../store/tiqs-actions.js'

export const initialState = {
  notification: false,
  isFetching: false,
  isError: false,
  riskExposureByAppId: {},
}

const reducer = handleActions(
  {
    [FETCH_RISK_EXPOSURE.REQUEST]: state => ({
      ...state,
      notification: null,
      isFetching: true,
      isError: false,
    }),

    [FETCH_RISK_EXPOSURE.SUCCESS]: (state, {payload}) =>
      _.thru(payload, riskExposure =>
        _.merge({}, state, {
          riskExposureByAppId: {
            [riskExposure.appId]: riskExposure,
          },
        })
      ),

    [FETCH_RISK_EXPOSURE.ERROR]: state =>
      _.merge({}, state, {
        notification: {
          message: I18n.t('apps.error'),
          isError: true,
        },
        isError: true,
      }),

    [FETCH_RISK_EXPOSURE.DONE]: state => ({
      ...state,
      isFetching: false,
    }),
  },
  initialState
)

export const getFetchState = state =>
  _.pick(state.apps.riskExposure, ['isFetching', 'isError'])

export const getRiskExposureNotification = state =>
  state.apps.riskExposure.notification

export const getRiskExposureByAppId = (state, {appId}) =>
  state.apps.riskExposure.riskExposureByAppId[appId] || []

export const createGetOwaspViolationsByAppId = () =>
  createSelector(getRiskExposureByAppId, riskExposures =>
    _.get(riskExposures, 'owaspViolations', [])
  )

export default reducer
