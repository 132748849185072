import _ from 'lodash-es'
import {handleActions} from 'redux-actions'
import createSelector from '../../../lib/create-selector.js'
import {featureOrOn, merge} from '../../../lib/utils.js'
import {isDeployed} from '../../../services/tenancy-helper.js'
import {
  READ_DEPLOYMENT_STATS,
  READ_ACTIVE_ISSUE_COUNTS,
} from '../../../store/l4e-actions.js'

export const initialState = {
  fetchState: {
    deploymentStats: {
      isFetching: false,
      isError: false,
    },
    activeIssueCounts: {
      isFetching: false,
      isError: false,
    },
  },
  totalDeviceCount: null,
  deviceCountByStatus: {},
  deviceCountByProtectionStatus: {},
  deviceCountByRisk: {},
  activeIssueCounts: [],
}

const reducer = handleActions(
  {
    [READ_DEPLOYMENT_STATS.REQUEST]: state =>
      merge({}, state, {
        fetchState: {
          deploymentStats: {
            isFetching: true,
            isError: false,
          },
        },
      }),

    [READ_ACTIVE_ISSUE_COUNTS.REQUEST]: state =>
      merge({}, state, {
        fetchState: {
          activeIssueCounts: {
            isFetching: true,
            isError: false,
          },
        },
      }),

    [READ_DEPLOYMENT_STATS.SUCCESS]: (state, {payload}) =>
      merge({}, state, {
        totalDeviceCount:
          payload.status.activated.total + payload.status.pending.total,
        deviceCountByStatus: {
          active:
            payload.status.activated.total -
            payload.status.disconnected -
            payload.status.uninstalled,
          pending: payload.status.pending.total,
        },
        deviceCountByProtectionStatus: {
          protected:
            payload.status.activated.total + payload.status.pending.total,
          disconnected: payload.status.disconnected,
          uninstalled: payload.status.uninstalled,
        },
        deviceCountByRisk: {
          ..._.reduceRight(
            payload.risk,
            (acc, counts, risk) => ({
              ...acc,
              [risk]: counts.total,
            }),
            {}
          ),
        },
      }),

    [READ_ACTIVE_ISSUE_COUNTS.SUCCESS]: (state, {payload}) => {
      const activeIssueCounts = _.sortBy(
        payload.map(issue => {
          const label = issue.type === 'application' ? 'app' : issue.type
          const high = issue.high[0][1]
          const medium = issue.medium[0][1]
          const low = issue.low[0][1]
          const total = high + medium + low

          return {
            label,
            total,
            high,
            medium,
            low,
          }
        }),
        'label'
      )

      return {...state, activeIssueCounts}
    },

    [READ_DEPLOYMENT_STATS.ERROR]: state =>
      merge({}, state, {
        fetchState: {
          deploymentStats: {
            isError: true,
          },
        },
      }),

    [READ_ACTIVE_ISSUE_COUNTS.ERROR]: state =>
      merge({}, state, {
        fetchState: {
          activeIssueCounts: {
            isError: true,
          },
        },
      }),

    [READ_DEPLOYMENT_STATS.DONE]: state =>
      merge({}, state, {
        fetchState: {
          deploymentStats: {
            isFetching: false,
          },
        },
      }),

    [READ_ACTIVE_ISSUE_COUNTS.DONE]: state =>
      merge({}, state, {
        fetchState: {
          activeIssueCounts: {
            isFetching: false,
          },
        },
      }),
  },
  initialState
)

export const getDeployment = state => state.deployment

export const getDeviceCountByStatus = state =>
  state.deployment.deviceCountByStatus

export const getDeviceCountByProtectionStatus = state =>
  state.deployment.deviceCountByProtectionStatus

export const createGetDeploymentStatus = () =>
  createSelector(
    [getDeviceCountByStatus, getDeviceCountByProtectionStatus],
    (deviceCountByStatus, deviceCountByProtectionStatus) =>
      _.reject(
        _.reduce(
          {...deviceCountByStatus, ...deviceCountByProtectionStatus},
          (acc, count, status) =>
            status === 'protected' ? acc : [...acc, {status, count}],
          []
        ),
        {
          status: featureOrOn('l4e_smb_enabled', 'l4e_pcp_only')
            ? 'pending'
            : null,
        }
      )
  )

export const createGetFleetDeviceCount = () =>
  createSelector(getDeviceCountByStatus, ({active}) => active || 0)

export const getTotalDeviceCount = state => state.deployment.totalDeviceCount

export const createGetPercentActive = () =>
  createSelector(
    [getDeviceCountByStatus, getTotalDeviceCount],
    (countByStatus, totalCount) => {
      if (countByStatus.active && totalCount) {
        return countByStatus.active / totalCount
      }

      return 0
    }
  )

export const createGetFleetStatus = () =>
  createSelector(
    getTotalDeviceCount,
    deviceCount => isDeployed() || deviceCount > 0
  )

export const getDeviceCountByRisk = state => state.deployment.deviceCountByRisk

export const createGetRiskyDeviceCount = () =>
  createSelector(getDeviceCountByRisk, deviceCountByRisk =>
    _.reduce(deviceCountByRisk, (acc, count) => acc + count, 0)
  )

export const createGetDeploymentRisk = () =>
  createSelector(
    [getDeviceCountByRisk, createGetRiskyDeviceCount()],
    (deviceCountByRisk, riskyDeviceCount) =>
      _.reduce(
        deviceCountByRisk,
        (acc, count, risk) => [
          ...acc,
          {
            risk,
            count,
            percent:
              riskyDeviceCount > 0 ? (count / riskyDeviceCount) * 100 : 0,
          },
        ],
        []
      )
  )

export const createGetSecurityStatus = () =>
  createSelector(
    [
      getDeviceCountByRisk,
      getDeviceCountByProtectionStatus,
      createGetRiskyDeviceCount(),
    ],
    // We can't destructure {protected} since it's a reserved word
    (deviceCountByRisk, deviceCountByProtectionStatus, riskyDeviceCount) =>
      _.isNumber(deviceCountByProtectionStatus.protected)
        ? {
            totalCount: deviceCountByProtectionStatus.protected,
            values: _.reverse(
              _.reduce(
                {
                  ...deviceCountByRisk,
                  secure:
                    deviceCountByProtectionStatus.protected - riskyDeviceCount,
                },
                (acc, count, risk) => [
                  ...acc,
                  {
                    risk,
                    count,
                    percent:
                      deviceCountByProtectionStatus.protected > 0
                        ? (count / deviceCountByProtectionStatus.protected) *
                          100
                        : 0,
                  },
                ],
                []
              )
            ),
          }
        : {
            count: null,
            values: [
              {risk: 'secure', count: null, percent: null},
              {risk: 'low', count: null, percent: null},
              {risk: 'medium', count: null, percent: null},
              {risk: 'high', count: null, percent: null},
            ],
          }
  )

export const getActiveIssueCounts = state => state.deployment.activeIssueCounts

export const createGetActiveIssueCountsWithTotals = () =>
  createSelector(getActiveIssueCounts, activeIssueCounts => {
    const totals = _.reduce(
      activeIssueCounts,
      (acc, value) => {
        const {high, medium, low} = value

        return {
          ...acc,
          total: acc.total + high + medium + low,
          high: acc.high + high,
          medium: acc.medium + medium,
          low: acc.low + low,
        }
      },
      {
        label: 'total',
        total: 0,
        high: 0,
        medium: 0,
        low: 0,
      }
    )

    return {totals, activeIssueCounts}
  })

export const deviceIssueTypes = ['configuration', 'file', 'os']

export const createGetActiveIssueCountsWithDevices = () =>
  createSelector(
    createGetActiveIssueCountsWithTotals(),
    ({totals, activeIssueCounts}) => {
      const [deviceIssueCounts, otherIssueCounts] = _.partition(
        activeIssueCounts,
        issue => _.includes(deviceIssueTypes, issue.label)
      )

      const reducedDeviceIssueCounts = _.reduce(
        deviceIssueCounts,
        (acc, {total, high, medium, low}) => ({
          ...acc,
          total: acc.total + total,
          high: acc.high + high,
          medium: acc.medium + medium,
          low: acc.low + low,
        }),
        {
          label: 'device',
          total: 0,
          high: 0,
          medium: 0,
          low: 0,
        }
      )

      return [
        totals,
        ..._.sortBy([...otherIssueCounts, reducedDeviceIssueCounts], 'label'),
      ]
    }
  )

export const createGetDeploymentStatsFetchState = () =>
  createSelector(
    getDeployment,
    ({
      fetchState: {
        deploymentStats: {isFetching, isError},
      },
    }) => ({
      isFetching,
      isError,
    })
  )

export const createGetActiveIssueCountsFetchState = () =>
  createSelector(
    getDeployment,
    ({
      fetchState: {
        activeIssueCounts: {isFetching, isError},
      },
    }) => ({
      isFetching,
      isError,
    })
  )

export default reducer
