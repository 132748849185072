import {combineActions, handleActions} from 'redux-actions'
import {
  READ_FLEET_ANALYZED_APPS,
  READ_GLOBAL_ANALYZED_APPS,
} from '../../../store/gds-actions.js'
import createSelector from '../../../lib/create-selector.js'
import {isDeployed} from '../../../services/tenancy-helper.js'
import {Source} from '../gds-param-types.js'
import {merge} from '../../../lib/utils.js'

export const initialState = {
  appCountsBySource: {
    [Source.FLEET]: null,
    [Source.GLOBAL]: null,
  },
}

const reducer = handleActions(
  {
    [combineActions(
      READ_FLEET_ANALYZED_APPS.SUCCESS,
      READ_GLOBAL_ANALYZED_APPS.SUCCESS
    )]: (state, {payload: {source, appCount}}) =>
      merge({}, state, {appCountsBySource: {[source]: appCount}}),
  },
  initialState
)

export const getAppCountsBySource = state =>
  state.securityCloud.analyzedApps.appCountsBySource

export const createGetIsFleetAppAnalysisDataPresent = () =>
  createSelector(
    getAppCountsBySource,
    appCountsBySource => !!(isDeployed() && appCountsBySource[Source.FLEET] > 0)
  )

export default reducer
