import _ from 'lodash-es'
import {handleActions} from 'redux-actions'
import I18n from '../../i18n.js'
import createSelector from '../../lib/create-selector.js'
import {FETCH_APP_PROPERTIES} from '../../store/gds-actions.js'

export const initialState = {
  notification: false,
  isFetching: false,
  isError: false,
  appPropertiesByAppId: {},
}

const reducer = handleActions(
  {
    [FETCH_APP_PROPERTIES.REQUEST]: state => ({
      ...state,
      notification: null,
      isFetching: true,
      isError: false,
    }),

    [FETCH_APP_PROPERTIES.SUCCESS]: (state, {payload}) =>
      _.merge({}, state, {
        appPropertiesByAppId: {
          [payload.appId]: payload,
        },
      }),

    [FETCH_APP_PROPERTIES.ERROR]: state =>
      _.merge({}, state, {
        notification: {
          message: I18n.t('apps.error'),
          isError: true,
        },
        isError: true,
      }),

    [FETCH_APP_PROPERTIES.DONE]: state => ({
      ...state,
      isFetching: false,
    }),
  },
  initialState
)

export const getFetchState = state =>
  _.pick(state.apps.appProperties, ['isFetching', 'isError'])

export const getAppPropertiesNotification = state =>
  state.apps.appProperties.notification

export const getAppPropertiesByAppId = (state, {appId}) =>
  state.apps.appProperties.appPropertiesByAppId[appId] || []

export const createGetCapabilitiesProperties = () =>
  createSelector(getAppPropertiesByAppId, appProperties =>
    _.get(appProperties, 'capabilities', [])
  )

export const createGetPermissionsProperties = () =>
  createSelector(getAppPropertiesByAppId, appProperties =>
    _.get(appProperties, 'permissions', [])
  )

export const createGetComponentsProperties = () =>
  createSelector(getAppPropertiesByAppId, appProperties =>
    _.get(appProperties, 'components', [])
  )

export default reducer
