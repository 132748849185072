import _ from 'lodash-es'
import lkt from '../lkt.js'

export const tenancies = () =>
  lkt.cache &&
  _.union(
    lkt.cache.org_tenancies,
    lkt.cache.tenancies,
    lkt.cache.commercial_partner_tenancies
  )

export const getTenancyBy = predicate => _.find(tenancies(), predicate)

export const cachedTenancy = () => lkt.tenancy

export const getEnt = tenancy =>
  _.thru(
    tenancy || cachedTenancy(),
    t => t?.ent || t?.org || t?.commercial_partner
  )

export const getEntType = tenancy =>
  _.thru(
    tenancy || cachedTenancy(),
    t =>
      (t?.ent && 'ent') ||
      (t?.org && 'org') ||
      (t?.commercial_partner && 'commercial_partner')
  )

export const getEntId = tenancy => getEnt(tenancy)?.id

export const getEntGuid = tenancy => getEnt(tenancy)?.guid

export const getAdmin = tenancy => (tenancy || cachedTenancy())?.admin

export const getFeatures = tenancy => (tenancy || cachedTenancy())?.features

export const getPermissions = tenancy =>
  (tenancy || cachedTenancy())?.permissions

export const hasPermissions = (permissions, tenancy) =>
  permissions === getPermissions(tenancy)

export const isOrgTenancy = tenancy => getEntType(tenancy) === 'org'

export const isCommercialPartnerTenancy = tenancy =>
  getEntType(tenancy) === 'commercial_partner'

export const isEntTenancy = tenancy => getEntType(tenancy) === 'ent'

export const isOrgEntTenancy = (tenancy, targetOrgGuid) =>
  isEntTenancy(tenancy) &&
  _.thru(getEnt(tenancy).org_guid, orgGuid =>
    targetOrgGuid ? targetOrgGuid === orgGuid : !!orgGuid
  )

export const isLocalAdmin = admin =>
  (admin || getAdmin()).idp_guid === lkt.config.keymaster_idp_guid

export const isSuperAdmin = admin => !!(admin || getAdmin()).super_admin

export const isDeployed = tenancy =>
  isOrgTenancy() || getEnt(tenancy)?.used_seats > 0

export const getTenancyByEntGuid = targetEntGuid =>
  getTenancyBy(t => getEntGuid(t) === targetEntGuid)

export const uiTenancies = () =>
  _.sortBy(
    _.filter(
      tenancies(),
      t =>
        !_.some(lkt.cache.org_tenancies, ot => isOrgEntTenancy(t, ot.org.guid))
    ),
    [
      // split into three groups: org, ent, commercial_partner
      tenancy =>
        isOrgTenancy(tenancy)
          ? 1
          : isEntTenancy(tenancy)
          ? 2
          : isCommercialPartnerTenancy(tenancy)
          ? 3
          : 4,
      // sort by name within a group
      tenancy => getEnt(tenancy).name,
    ]
  )

export const tenanciesCount = () => _.size(uiTenancies())

export const hasMultipleTenancies = () => tenanciesCount() > 1

export const firstTenancy = () => _.head(uiTenancies())

export const defaultEntGuid = () =>
  hasMultipleTenancies() ? undefined : getEntGuid(firstTenancy())

export const isTenancyCached = (targetEntGuid = defaultEntGuid()) =>
  !!targetEntGuid && getEntGuid() === targetEntGuid

export const setCachedTenancy = tenancy => {
  lkt.tenancy = tenancy
  lkt.features = getFeatures(tenancy)
  return tenancy
}

// A special case helper, when the server state of tenancy (ent, admin) was changed
// due to UI<->server activities and we don't wnt to reload the L4E tenancies cache.
// So we just update our cached tenancy in memory, hoping that the UI will get
// more or less adequate to server tenancy state.
export const updateCachedTenancy = tenancy => {
  if (cachedTenancy()?.id === tenancy.id)
    setCachedTenancy({...cachedTenancy(), ...tenancy})

  if (isEntTenancy(tenancy))
    lkt.cache.tenancies = _.union(
      _.reject(lkt.cache.tenancies, {id: tenancy.id}),
      [tenancy]
    )

  if (isOrgTenancy(tenancy))
    lkt.cache.org_tenancies = _.union(
      _.reject(lkt.cache.org_tenancies, {id: tenancy.id}),
      [tenancy]
    )

  if (isCommercialPartnerTenancy(tenancy))
    lkt.cache.commercial_partner_tenancies = _.union(
      _.reject(lkt.cache.commercial_partner_tenancies, {
        id: tenancy.id,
      }),
      [tenancy]
    )
}
