import _ from 'lodash-es'
import {ServerError, isNetworkError, isAbortError} from '@lookout/request'
import {urlJoin} from '../lib/utils.js'
import {apiUrlFor, argosApiUrlFor} from './api.js'
import {getEntGuid, isOrgTenancy} from './tenancy-helper.js'

const l4eUrl = ({id, urlNamespace}) =>
  apiUrlFor(
    urlJoin(
      isOrgTenancy() ? '/orgs' : '/ents',
      getEntGuid(),
      urlNamespace,
      `/async_operations/${id}`
    ),
    {ver: 2}
  )

const argosUrl = ({exportGuid}) =>
  argosApiUrlFor(`/${getEntGuid()}/export/${exportGuid}`, {
    ver: 3,
  })

const asyncExportUrl = options => ({
  l4e: l4eUrl(options),
  argos: argosUrl(options),
})

const requestArgs = ({result, urlNamespace, apiKind}) =>
  apiKind === 'argos'
    ? {exportGuid: result.guid}
    : {id: result.id, urlNamespace}

const parseResponse = (response, apiKind) =>
  apiKind === 'argos' ? {data: response} : response

const asyncOperation = async (
  request,
  operation,
  {
    min = 100, // milliseconds
    max = 30000, // milliseconds
    maxRequestAttempts = 5,
    urlNamespace = '',
    apiKind = 'l4e',
    ...options
  } = {}
) => {
  let result = operation
  let networkErrorCount = 0
  let interval

  const trackProgress = (resolve, reject) => {
    // Calculate exponential back-off with de-correlated jitter.
    // https://www.awsarchitectureblog.com/2015/03/backoff.html
    interval = !interval ? min : Math.min(max, _.random(min, interval * 3))
    if (result.state === 'ready') {
      resolve(parseResponse(result, apiKind))
    } else if (result.state === 'error')
      reject(
        new ServerError(result.data?.error || result.data?.error_code, {
          body: result.data,
        })
      )
    else
      _.delay(async () => {
        try {
          result = await request(
            asyncExportUrl(requestArgs({result, urlNamespace, apiKind}))[
              apiKind
            ],
            options
          )
          networkErrorCount = 0
          trackProgress(resolve, reject)
        } catch (error) {
          if (isNetworkError(error)) {
            if (
              (error.response.status >= 400 && error.response.status < 500) ||
              networkErrorCount >= maxRequestAttempts
            )
              reject(error)
            else {
              networkErrorCount += 1
              trackProgress(resolve, reject)
            }
          } else if (!isAbortError(error)) throw error
        }
      }, interval)
  }

  return new Promise(trackProgress)
}

export default asyncOperation
