import {stringifyQuery, urlJoin} from '../lib/utils.js'
import request from './request.js'
import {fetch} from './fetch-helper.js'
import {
  getEntGuid,
  isOrgTenancy,
  getEntType,
  getAdmin,
} from './tenancy-helper.js'
import {apiUrlFor, authApiUrlFor} from './api.js'
import requestWithEntGuid from './request-with-ent-guid.js'

export const readEnrollmentCode = async ({entGuid = getEntGuid(), ...opts}) =>
  fetch(request, apiUrlFor(`/ents/${entGuid}/invites/qrcode`, {ver: 2}), opts)

export const readCompliance = async opts =>
  fetch(requestWithEntGuid, apiUrlFor('/ent/compliance'), opts)

export const updateEnt = async ({tenancy, data, ...opts}) =>
  fetch(
    request,
    apiUrlFor(
      `${isOrgTenancy(tenancy) ? '/orgs' : '/ents'}/${getEntGuid(tenancy)}`,
      {ver: 2}
    ),
    {method: 'PATCH', body: JSON.stringify(data), ...opts}
  )

export const createEntLoginEvent = async ({tenancy, accept, ...opts}) =>
  fetch(
    request,
    apiUrlFor(
      `/${getEntType(tenancy)}s/${getEntGuid(
        tenancy
      )}/admin/login?${stringifyQuery({
        accept,
      })}`,
      {ver: 2}
    ),
    {method: 'POST', ...opts}
  )

export const assumeRole = async ({tenancy, ...opts}) =>
  fetch(
    request,
    authApiUrlFor(
      `accounts/${getAdmin(tenancy).guid}/enterprises/${getEntGuid(
        tenancy
      )}/assume_role`
    ),
    {method: 'POST', ...opts}
  )

export const createEntLogoutEvent = async opts =>
  fetch(
    request,
    apiUrlFor(`/${getEntType()}s/${getEntGuid()}/admin/logout`, {ver: 2}),
    {method: 'POST', ...opts}
  )

export const updateAdmin = async ({adminGuid, data, ...opts}) =>
  fetch(
    request,
    apiUrlFor(`/ents/${getEntGuid()}/admins/${adminGuid}`, {ver: 2}),
    {
      method: 'PATCH',
      body: JSON.stringify(data),
      ...opts,
    }
  )

const logoUrl = ({product} = {}) =>
  apiUrlFor(urlJoin(`/ents/${getEntGuid()}/customized_logos`, product), {
    ver: 2,
  })

export const createLogo = async ({product, file, contentType, ...opts}) =>
  fetch(request, logoUrl({product}), {
    method: 'POST',
    body: file,
    headers: {'Content-Type': contentType},
    ...opts,
  })

export const readLogos = async opts => fetch(request, logoUrl(), opts)

export const readLogo = async ({product, ...opts}) =>
  fetch(request, logoUrl({product}), opts)

export const deleteLogo = async ({product, ...opts}) =>
  fetch(request, logoUrl({product}), {method: 'DELETE', ...opts})
