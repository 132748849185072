import _ from 'lodash-es'
import reduceReducers from 'reduce-reducers'
import {handleActions} from 'redux-actions'
import {max} from 'd3-array'
import {merge} from '../../../lib/utils.js'
import createSelector from '../../../lib/create-selector.js'
import {Source} from '../gds-param-types.js'
import {READ_MULTISERIES} from '../../../store/gds-actions.js'
import {isError} from '../../../services/fetch-helper.js'
import {getUIBindings} from './issue-trends-ui-reducer.js'
import createIssueTrendsFetchStateReducer from './create-issue-trends-fetch-state-reducer.js'

export const initialState = {
  byDateRange: null,
  globalReadState: null,
  fleetReadState: null,
}

const reducer = handleActions(
  {
    [READ_MULTISERIES.SUCCESS]: (state, {payload}) =>
      merge({}, state, {
        byDateRange: {
          [payload.dateRange]: _.concat(
            state.byDateRange?.[payload.dateRange] || [],
            payload.multiseries
          ),
        },
      }),
  },
  initialState
)

export const getMultiseries = state => state.issueTrends.multiseries

export const createGetMultiseriesByDateRange = () =>
  createSelector(
    [getMultiseries, getUIBindings],
    (multiseries, ui) => multiseries?.byDateRange?.[ui.dateRangeSelection]
  )

export const createGetIsAnalyzingFleetMultiseriesData = () =>
  createSelector(getMultiseries, ({fleetReadState}) => isError(fleetReadState))

export const createGetMultiseriesBySource = () =>
  createSelector(
    [
      createGetMultiseriesByDateRange(),
      createGetIsAnalyzingFleetMultiseriesData(),
      getUIBindings,
    ],
    (multiseries, isAnalyzingFleetData, ui) =>
      _.filter(multiseries, timeseries => {
        if (ui.sourceSelection === Source.COMPARISON || isAnalyzingFleetData) {
          return true
        }

        return timeseries?.source === ui.sourceSelection
      })
  )

export const createGetFilteredMultiseries = () =>
  createSelector(
    [createGetMultiseriesBySource(), getUIBindings],
    (multiseries, ui) =>
      _.filter(
        multiseries,
        timeseries => ui.issueTypeFilters[timeseries?.issueType]
      )
  )

export const createGetLastElementsForMultiseries = () =>
  createSelector(createGetFilteredMultiseries(), multiseries =>
    multiseries.map(series => ({...series, values: [_.last(series.values)]}))
  )

export const createGetDependentMax = () =>
  createSelector(
    [createGetFilteredMultiseries(), getUIBindings],
    multiseries => {
      let dependentMax = 100

      const flatten = m => _.flatten(m.map(timeseries => timeseries.values))
      const getMaxPercent = v => max(v, d => d.percent)

      if (multiseries.length > 0) {
        const values = flatten(multiseries)
        dependentMax = getMaxPercent(values)
      }

      return dependentMax
    }
  )

export const createGetReadState = () =>
  createSelector(getMultiseries, ({globalReadState, fleetReadState}) => ({
    globalReadState,
    fleetReadState,
  }))

export default reduceReducers(
  reducer,
  createIssueTrendsFetchStateReducer({
    actionType: READ_MULTISERIES,
    initialState,
  })
)
